import * as React from 'react'
import * as moment from 'moment';
import { Helmet } from 'react-helmet';
import {
  BrowserView,
  TabletView,
  MobileView
} from "react-device-detect";
import favicon from '../assets/images/favicon.ico';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Grid from '@material-ui/core/Grid';
import Countdown from '../components/Countdown/Countdown';
import CreateSalesLead from '../components/CreateSaleLead/CreateSalesLead';

import firebase from "gatsby-plugin-firebase"

interface ILandingPage {
  data: {
    prismicLandingpage: {
      data: {
        image_one: {
          alt: string,
          copyright: string,
          url: string,
          thumbnails: string
        },
        image_two: {
          alt: string,
          copyright: string,
          url: string,
          thumbnails: string
        },
        coming_soon: {
          alt: string,
          copyright: string,
          url: string,
          thumbnails: string
        },
        logo: {
          alt: string,
          copyright: string,
          url: string,
          thumbnails: string
        },
        product_features: {
          html: string;
        },
        value_proposition: {
          html: string;
        },
        copyright: {
          html: string;
        },
        background: {
          html: string;
        }
      }
    }
  }
}

interface IState {
  isLoading: boolean;
}

export default class LandingPage extends React.Component<ILandingPage, IState> {
  constructor(props: ILandingPage, state: IState) {
    super(props, state)

    this.state = {
      isLoading: true
    }
  }

  getSiteMetaData() {
    return (
      <Helmet
        title="Proper.ly | Coming soon ..."
        meta={[
          { name: 'og:site_name', content: 'Proper.ly' },
          { name: 'og:description', content: 'Estate agent property management system, facilitating the trading of real estate ownership' },
          { name: 'og:image', content: 'https://images.prismic.io/proper-ly/0450bda8-2a3c-4423-8eb7-e95bfed3e699_Transparent.svg?auto=compress,format' },
          { name: 'keywords', content: 'property, saas, software, system, properly, estate, agent' },
        ]}
        link={[
          { rel: 'icon', href: favicon }
        ]}
      />
    )
  }

  componentDidMount() {
    this.setState({
      isLoading: (this.props.data && this.props.data.prismicLandingpage) ? false : true
    } as IState);
    if (firebase) {
      firebase.analytics().logEvent("visted_landing_page");
    } else {
      console.log('firebase doesn\'t exist');
    }
  }

  render() {
    const { isLoading } = this.state;
    if (!isLoading) {
      const { data } = this.props.data.prismicLandingpage;
      // HTML content
      const valueProposition = { __html: data.value_proposition.html };
      const background = { __html: data.background.html };
      const copyright = { __html: data.copyright.html };
      const timeTillDate = '2021-09-01';
      return (
        <div>
          {this.getSiteMetaData()}
          <BrowserView>
            <div className="content">
              <AppBar className="header">
                <Toolbar>
                  <img className="logo" src={data.logo.url} alt={data.logo.alt} />
                </Toolbar>
              </AppBar>              
              <div className="main">
                <img className="comingSoon" src={data.coming_soon.url} alt={data.coming_soon.alt} />
                <div className="title" dangerouslySetInnerHTML={valueProposition}></div>
                <div className="background" dangerouslySetInnerHTML={background}></div>
                <CreateSalesLead />
                <div className="images">
                  <img className="imageOne" src={data.image_one.url} alt={data.image_one.alt} />
                  <img className="imageTwo" src={data.image_two.url} alt={data.image_two.alt} />
                </div>
              </div>
              <div className="countdown">
                <Countdown timeTillDate={timeTillDate} />
                </div>
            </div>
            <div className="footer" dangerouslySetInnerHTML={copyright}></div>
          </BrowserView>
          <TabletView>

          </TabletView>
          <MobileView viewClassName="mobile">
            <div className="content">
              <AppBar className="header">
                <Toolbar>
                  <img className="logo" src={data.logo.url} alt={data.logo.alt} />
                </Toolbar>
              </AppBar>
              <div className="main">
                <Grid container spacing={3}>
                  <Grid className="column" item xs={12} sm={4}>
                    <div className="title" dangerouslySetInnerHTML={valueProposition}></div>
                    <div className="background" dangerouslySetInnerHTML={background}></div>
                    {/* <img className="illustration" src={data.illustration.url} alt={data.illustration.alt} /> */}
                    <CreateSalesLead />
                    <Countdown timeTillDate={timeTillDate} />
                    <i className="disclaimer">** Planned launch date is the <strong>1<sup>st</sup> December 2020</strong>, subject to change.</i>
                  </Grid>
                </Grid>
              </div>
            </div>
            <div className="footer" dangerouslySetInnerHTML={copyright}></div>
          </MobileView>
        </div>
      );
    } else {
      return (<div>Loading ...</div>);
    }
  }
}