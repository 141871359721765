import * as React from 'react'
import * as moment from 'moment';
import './Countdown.css';
import { isMobile } from 'react-device-detect';

interface IProps {
  timeTillDate: any;
}

interface IState {
  days: number;
  hours: string;
  minutes: string;
  seconds: string;
}

export default class Countdown extends React.Component<IProps, IState> {
  constructor(props: IProps, state: IState) {
    super(props, state)

    this.state = {
      days: undefined,
      hours: undefined,
      minutes: undefined,
      seconds: undefined
    }
  }

  interval: any = undefined;

  componentDidMount() {
    this.interval = setInterval(() => {
      const { timeTillDate } = this.props;
      const then = moment(timeTillDate);
      const now = moment();
      const countdown = moment(then.diff(now));
      const days = then.diff(now, 'days');
      const hours = countdown.format('HH');
      const minutes = countdown.format('mm');
      const seconds = countdown.format('ss');

      this.setState({ days, hours, minutes, seconds });
    }, 1000);
  }

  componentWillUnmount() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  render() {
    const { days, hours, minutes, seconds } = this.state;
    return (
      <div className={ isMobile ? 'mobile' : '' }>
        <div className="countdown-wrapper">
          <h5>Launching in ... </h5>
          <div>
            <span>{days}</span>
            <div className="smalltext">Days</div>
          </div>
          <div>
            <span>{hours}</span>
            <div className="smalltext">Hours</div>
          </div>
          <div>
            <span>{minutes}</span>
            <div className="smalltext">Minutes</div>
          </div>
          <div>
            <span>{seconds}</span>
            <div className="smalltext">Seconds</div>
          </div>
        </div>        
      </div>
    );
  }
}