import * as React from 'react';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import InputBase from '@material-ui/core/InputBase';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import { isMobile } from "react-device-detect";

import addToMailchimp from 'gatsby-plugin-mailchimp';
import firebase from "gatsby-plugin-firebase"

import './CreateSalesLead.css';

interface IProps {}

interface IState {
  email: string;
  open: boolean;
  message: string;
}

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default class CreateSalesLead extends React.Component<IProps, IState> {
  constructor(props: IProps, state: IState) {
    super(props, state)

    this.state = {
      email: undefined,
      open: false,
      message: 'Thanks for subscribing ... we will keep you in the loop :)'
    }
    this.handleOnSubmit = this.handleOnSubmit.bind(this);
    this.handleOnChange = this.handleOnChange.bind(this);
    this.handleOnClose = this.handleOnClose.bind(this);
  }

  async handleOnSubmit() {
    addToMailchimp(this.state.email).then((data: any) => {
      this.setState({ 
        email: undefined,
        open: true,
        message: data.msg
      });
      if (firebase) {
        firebase.analytics().logEvent("email_subscription");
      } else {
        console.log('firebase doesn\'t exist');
      }
    }).catch((error: any) => {
      console.warn('Error: ', JSON.parse(error).msg);
    });
  }

  handleOnChange(event: any) {
    this.setState({ email: event.target.value });
  };

  handleOnClose() {
    this.setState({ open: false });
  }

  render() {
    const { open, message } = this.state;
    return (
      <div className={ isMobile ? 'mobile' : 'container'}>
        {/* <p className="message">The countdown has begun, subscribe now for periodic updates on our progress</p> */}
        <Grid className="wrapper" container spacing={3}>
          <Grid item xs={8}>
            <Paper className="textbox">
              <InputBase className="input"
                placeholder="Your Email"
                onChange={this.handleOnChange} />
            </Paper>
          </Grid>
          <Grid item xs={4}>
            <Button className="subscribe"
              variant="contained"
              onClick={this.handleOnSubmit}>
              Subscribe
          </Button>
          </Grid>
          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={open}
            autoHideDuration={30000}
            onClose={this.handleOnClose}>
            <Alert className="alert" onClose={this.handleOnClose} severity="success">
              <span dangerouslySetInnerHTML={{ __html: message }}></span>
            </Alert>
          </Snackbar>
        </Grid>
      </div>
    );
  }
}